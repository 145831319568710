import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "en-career",
  "bg": "/img/bg-career.jpg",
  "title": "Work at Zimply",
  "seo": {
    "title": "Career",
    "descr": "-"
  },
  "textBlocks": [{
    "text": "At Zimply Innovation, we offer you as an employee to grow together with the company and an opportunity to influence and affect our company’s future. We are a young entrepreneurial company with great ambitions and we are expanding rapidly. We offer competence development and attractive work in new innovative and ground-breaking technology with many interesting projects. The well-being and knowledge development of our employees is our top priority in order to be able to attract and retain the very best experts in the field."
  }, {
    "text": "The workplace has an inclusive and transparent culture and is located in beautiful premises in the heart of Stockholm."
  }, {
    "text": "At Zimply, we value having fun at work. Whether it’s about exercising, playing table tennis, traveling or developing Digital Assistants together. Work-Life balance is important to us and we believe it is the only way to continuously focus and have energy to be able to deliver the best results with high quality."
  }, {
    "text": "Are you motivated, down to earth, focused and have experience from Intelligent Automation, RPA, Computer Vision, IPA, Cognitive Automation, Chatbots or Machine Learning? We would like to get in touch with you. Email, call or drop by our office!"
  }],
  "quoteSection": {
    "title": "What is the best thing about working at Zimply?",
    "text": "“The opportunity to have an influence and that we have great fun together. We are all very helpful to each other and believe in cooperation. Since I started, I have had a great exchange of knowledge from awesome developers and here you learn ground-breaking technologies in AI, Intelligent Automation and Machine Learning.”",
    "personImage": "/img/sebastian.jpg",
    "personName": "Sebastian André",
    "role": "Product Manager & Developer"
  },
  "servicesTitle": "Apply for our vacant jobs",
  "servicesBg": "/img/pink.png",
  "servicesSection": [{
    "title": "Business Analyst",
    "text": "We are looking for an analytical and structured Business Analyst",
    "path": "career/business-analyst"
  }, {
    "title": "Intelligent Automation Developer",
    "text": "We are looking for a creative and driven developer to expand our team",
    "path": "career/intelligent-automation-developer"
  }, {
    "title": "Sales executive",
    "path": "career/sales-executive",
    "text": "-"
  }],
  "endSection": {
    "title": "Learn more about Zimply",
    "icon": "/img/bulb.png",
    "buttonText": "Go to",
    "buttonPath": "about"
  }
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      